<template>
    <v-snackbar
            v-model="snackbar"
            :multi-line="multiLine"
            outlined
            :color="color"
            :timeout="time">
        {{$t(text)}}
        <template v-slot:action="{ attrs }">
            <v-btn
                    :color="color"
                    text
                    v-bind="attrs"
                    @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        data() {
            return {
                multiLine: true,
                snackbar : false,
                text     : "",
                color    : "",
                time     : -1,
            }
        },
        beforeMount() {
            this.eventbus.$on("SB", (message, color = "white", time = 10) => { // Open snackbar
                this.text     = message;
                this.color    = color;
                this.time     = time * 1000; // Time in Seconds!
                this.snackbar = true;
            });
        },
    }
</script>